import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
let NoPermissionPage = class NoPermissionPage extends Vue {
};
NoPermissionPage = __decorate([
    Component({
        name: 'no-permission-page',
        components: {
            NoPermission: () => import('@/components/global/no-permission.vue'),
        },
    })
], NoPermissionPage);
export default NoPermissionPage;
